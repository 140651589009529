/* eslint-disable eqeqeq */
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import '../activity.scss';
import {
  AvForm,
  AvField,
  AvCheckbox,
  AvCheckboxGroup,
} from 'availity-reactstrap-validation';
import {
  Grid,
  Segment,
  Search,
  Table,
  Button,
  Modal,
  Icon,
  Loader,
  Label,
} from 'semantic-ui-react';
import { CustomTable, CustomTooltip } from '../../../../components';
import {
  ParticipantDelete,
  ParticipantPermissionUpdate,
} from '../../participants';
import {
  addActivityMember,
  deleteActivityMember,
  getOccupationTypes,
  getOccupation,
  getOrgMembers,
  reInviteWithdrawnMember,
} from '../../../../store/actions';
import { RESTRICTION_TYPES, permissionTypes } from '../../../../utils/constant';
import {
  isEmpty,
  cloneDeep,
  debounce,
  get,
  includes,
} from '../../../../utils/lodash';
import moment from 'moment';
import { getPermissionType } from '../../../../utils/activity/userPermission';

class ActivityMemberSteps extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      data: [],
      searchQuery: '',
      form: {
        firstName: '',
        surName: '',
        email: '',
        message: '',
      },
      permissionType: undefined,
      radioType: undefined,
      collaborator: false,
      documentor: false,
      reportOnly: false,
      permissions: [],
      permissionError: false,
      modal: false,
      dropdownValuePermission: '',
      isCustomNotEmpty: true,
      isPermissionLabelVisible: false,
    };
  }

  componentDidMount() {
    let permissionArray = permissionTypes.map((permissionType) => {
      return {
        name: permissionType.title,
        permission: {
          read: false,
          write: false,
        },
        state: permissionType.state,
      };
    });
    this.setState({ permissionArray });
  }

  componentWillMount() {
    this.resetComponent();
  }

  resetComponent = () =>
    this.setState({
      isLoading: false,
      data: [],
      searchQuery: '',
    });

  handleResultSelect = (user) => {
    if (isEmpty(user)) {
      return false;
    } else {
      this.toggle();
      this.setState(
        {
          form: {
            firstName: user.firstName,
            surname: user.surname,
            email: user.email,
          },
        },
        () => {
          this.form && this.form.reset(); //check if this reset works
        }
      );
    }
  };

  getColumns = (currentActivity, user) => {
    return [
      {
        width: 4,
        title: 'Current Members',
        render: (data) => {
          return data.user ? (
            <div className="current-member-label">
              <div> {data.user.name} </div>
              {data.isOwner &&
                !data.withdrawnDateTime &&
                currentActivity.ownerId === data.userId && (
                  <div className="activity-owner">Activity Manager</div>
                )}
              {data.isOwner && data.withdrawnDateTime && (
                <div className="activity-owner-withdrawn">Activity Manager</div>
              )}
              {!data.user.email && (
                <div className="deleted-user-tag">Deleted User</div>
              )}
            </div>
          ) : null;
        },
      },
      {
        width: 4,
        title: 'Email',
        render: (data) => {
          return (
            <div>
              {data.user
                ? data.user.email
                  ? data.user.email
                  : data.user.maskedEmail
                : null}
            </div>
          );
        },
      },
      {
        width: 2,
        title: 'Permission',
        render: (data) => {
          return (
            <div>
              {getPermissionType(data.permissions) === 'reportOnly'
                ? 'Report Only'
                : getPermissionType(data.permissions) === 'documentor'
                  ? 'Documentor'
                  : getPermissionType(data.permissions) === 'collaborator'
                    ? 'Collaborator'
                    : 'Custom'}
            </div>
          );
        },
      },
      {
        width: 2,
        title: 'Status',
        render: (data) => {
          let parentOwner = get(currentActivity, 'parentActivity.ownerId');
          const isWithdrawnUser =
            data && data.withdrawnDateTime ? data.withdrawnDateTime : false;

          let status = '';
          if (!isWithdrawnUser && parentOwner === data.userId) {
            status = 'Program Manager';
          } else if (!isWithdrawnUser) {
            status = 'Program Collaborator';
          }

          const memberStatus =
            data.status.charAt(0).toUpperCase() + data.status.slice(1);

          return (
            <div>
              {data.isProgramCollaborator ? (
                status
              ) : currentActivity.ownerId === data.userId ? (
                <div className="member-role">Activity Manager</div>
              ) : data.status.charAt(0).toUpperCase() + data.status.slice(1) ? (
                <div
                  className={
                    memberStatus === 'Withdrawn' || memberStatus === 'Deleted'
                      ? 'text-danger'
                      : 'member-role'
                  }
                >
                  {data.status.charAt(0).toUpperCase() + data.status.slice(1)}
                </div>
              ) : null}
            </div>
          );
        },
      },
      {
        width: 1,
        title: 'Date',
        render: (data) => {
          const startDate = data.inviteAcceptedDateTime
            ? moment(data.inviteAcceptedDateTime).format('YYYY-MM-DD')
            : data.inviteSentDateTime
              ? moment(data.inviteSentDateTime).format('YYYY-MM-DD')
              : null;
          const endDate =
            startDate && data.withdrawnDateTime
              ? moment(data.withdrawnDateTime).format('YYYY-MM-DD')
              : null;
          return (
            <div>
              {data.inviteAcceptedDateTime ? (
                <>
                  {startDate && `${startDate}`}
                  {endDate && ` to ${endDate}`}
                </>
              ) : (
                <>{endDate && endDate}</>
              )}
            </div>
          );
        },
      },
      {
        title: '',
        width: 1,
        render: (data) => {
          const { reinviteWithdrawnLoading } = this.props;
          return (
            <Segment disabled={currentActivity.statusId === 3 ? true : false}>
              {currentActivity.ownerId !== data.userId &&
              data.status !== 'withdrawn' &&
              data.status !== 'deleted' &&
              user.id == currentActivity.ownerId ? (
                <ParticipantPermissionUpdate
                  triggerComp={
                    <label className="activity-actions-warn mb-0">Edit</label>
                  }
                  participant={data}
                  {...this.props}
                />
              ) : null}
              {currentActivity.ownerId !== data.userId &&
                user.id == currentActivity.ownerId &&
                data.status === 'withdrawn' &&
                data.status !== 'deleted' && (
                  <label
                    className="activity-actions-warn mb-0"
                    onClick={() => this.handleWithdrawnMember(data)}
                  >
                    {' '}
                    {reinviteWithdrawnLoading ? (
                      <Loader active size="tiny" indeterminate />
                    ) : (
                      !data.isUserDeleted && 'Re-Invite'
                    )}
                  </label>
                )}
            </Segment>
          );
        },
      },
      {
        title: '',
        width: 1,
        render: (data) => {
          return (
            <div>
              <Segment
                disabled={currentActivity.statusId === 3 ? true : false}
                className="action-container"
              >
                {!data.isProgramCollaborator &&
                currentActivity.ownerId !== data.userId &&
                data.status !== 'withdrawn' &&
                data.status !== 'deleted' &&
                user.id == currentActivity.ownerId ? (
                  <ParticipantDelete
                    participant={data}
                    triggerComp={
                      <label className="activity-actions-error first-down mb-0">
                        Remove
                      </label>
                    }
                    {...this.props}
                  />
                ) : null}
              </Segment>
            </div>
          );
        },
      },
    ];
  };

  async handleWithdrawnMember(memberInfo) {
    const { reInviteWithdrawnMember, currentActivity } = this.props;

    let data = {
      firstName: memberInfo.user.firstName,
      surname: memberInfo.user.surname,
      email: memberInfo.user.email,
    };
    await reInviteWithdrawnMember(currentActivity.id, data);
  }
  handleSearchChange = (e, { value }) => {
    this.setState({ isLoading: true, searchQuery: value }, async () => {
      try {
        const { getOrgMembers, currentActivity } = this.props;
        await getOrgMembers(value);
        let {
          orgMembers,
          // actMembers
        } = this.props;
        setTimeout(async () => {
          if (this.state.searchQuery.length < 1) return this.resetComponent();
          // orgMembers = map(orgMembers, member => {
          //   const exist = find(
          //     actMembers,
          //     actMember => member.email === actMember.user.email
          //   );
          //   if (!exist) return member;
          // });
          this.setState({
            isLoading: false,
            data: orgMembers || [],
          });
        }, 300);
      } catch (error) {
        // console.error('handleSearchChange -> error', error);
      }
    });
  };

  renderCurrentMemberCard() {
    const { actMembers, currentActivity, user } = this.props;

    // get only activity members (without temporary added admin memberships)
    const actMembersWithoutAdmins = actMembers.filter(
      (member) => !member.isAdminPermission
    );

    return (
      <CustomTable
        header
        columns={this.getColumns(currentActivity, user)}
        data={actMembersWithoutAdmins}
        customClass="activity-members-table"
        emptyMessage="No users has been added"
        keyExtractor={(item, index) => {
          return item.id;
        }}
      />
    );
  }

  handleInvalidSubmit() {
    const { permissionType } = this.state;
    let error = false;

    if (!permissionType) {
      this.setState({ permissionError: true });
      error = true;
    } else {
      this.setState({ permissionError: false });
    }

    return error;
  }

  async handleValidSubmit(event, values) {
    const { isCustomNotEmpty } = this.state;
    delete values.permission;
    const { addActivityMember, currentActivity } = this.props;
    try {
      const error = this.handleInvalidSubmit();
      if (error) {
        return false;
      }
      values.projectId = currentActivity.id;
      values.permissions = this.state.permissionArray;
      if (isCustomNotEmpty) {
        await addActivityMember(values);

        this.setState(
          {
            isLoading: false,
            data: [],
            searchQuery: '',
            form: {
              firstName: '',
              surName: '',
              email: '',
              message: '',
            },
            permissionType: undefined,
            radioType: undefined,
            collaborator: false,
            documentor: false,
            reportOnly: false,
            permissionError: false,
            permissionArray: undefined,
            isCustomNotEmpty: true, //false means empty,
            isPermissionLabelVisible: false,
            dropdownValuePermission: '',
          },
          () => {
            this.form && this.form.reset();
            this.toggle();
          }
        );
      } else {
        this.setState({
          isPermissionLabelVisible: true,
        });
        // NotificationManager.warning(
        //   'Please select a permission',
        //   'Permission Empty'
        // );
      }
    } catch (error) {
      // console.error('handleValidSubmit -> error', error);
    }
  }

  selectPermissionType(value, name, checked) {
    // if (value === 'collaborator') {
    //   name = 'collaborator';
    // } else if (value === 'documentor') {
    //   name = 'documentor';
    // } else if (value === 'reportOnly') {
    //   name = 'reportOnly';
    // }

    if (value === 'collaborator') {
      let permissionArray = permissionTypes.map((permissionType) => {
        return {
          name: permissionType.title,
          permission: {
            read: true,
            write: RESTRICTION_TYPES.includes(permissionType.state)
              ? false
              : true,
          },
          state: permissionType.state,
        };
      });
      this.setState({
        permissionArray,
        permissionType: 'Collaborator',
        radioType: value,
        permissionError: false,
        dropdownValuePermission: 'collaborator',
        isCustomNotEmpty: true,
        isPermissionLabelVisible: false,
      });
    } else if (value === 'documentor') {
      let permissionArray = permissionTypes.map((permissionType) => {
        return {
          name: permissionType.title,
          permission: {
            read: permissionType.documentor ? true : false,
            write: permissionType.documentor
              ? RESTRICTION_TYPES.includes(permissionType.state)
                ? false
                : true
              : false,
          },
          state: permissionType.state,
        };
      });
      this.setState({
        permissionArray,
        permissionType: 'Documentor',
        radioType: value,
        permissionError: false,
        dropdownValuePermission: 'documentor',
        isCustomNotEmpty: true,
        isPermissionLabelVisible: false,
      });
    } else if (value === 'reportOnly') {
      let permissionArray = permissionTypes.map((permissionType) => {
        return {
          name: permissionType.title,
          permission: {
            read: permissionType.reportOnly ? true : false,
            write: permissionType.reportOnly
              ? RESTRICTION_TYPES.includes(permissionType.state)
                ? false
                : true
              : false,
          },
          state: permissionType.state,
        };
      });
      this.setState({
        permissionArray,
        permissionType: 'Report Only',
        radioType: value,
        permissionError: false,
        dropdownValuePermission: 'reportOnly',
        isCustomNotEmpty: true,
        isPermissionLabelVisible: false,
      });
    } else if (value === 'custom') {
      let permissionArray = permissionTypes.map((permissionType) => {
        return {
          name: permissionType.title,
          permission: {
            read: false,
            write: false,
          },
          state: permissionType.state,
        };
      });

      let isCustomEmpty = permissionArray.map((item) => {
        return item.permission.read == false && item.permission.write == false
          ? true
          : false;
      });

      this.setState({
        permissionArray,
        permissionType: 'Custom',
        radioType: value,
        permissionError: false,
        dropdownValuePermission: 'custom',
        isCustomNotEmpty: includes(isCustomEmpty, false),
        isPermissionLabelVisible: false,
      });
    }
  }

  selectPermissionOption(checked, value, id, type) {
    this.setState((preState) => {
      let permissionArray = cloneDeep(preState.permissionArray);
      if (!checked && type === 'read') {
        permissionArray[id].permission.write = false;
      }
      permissionArray[id].permission[type] =
        !preState.permissionArray[id].permission[type];

      let dropdownValue = getPermissionType(permissionArray);

      let isCustomEmpty = permissionArray.map((item) => {
        return item.permission.read == false && item.permission.write == false
          ? true
          : false;
      });

      return {
        ...preState,
        permissionArray: permissionArray,
        permissionType: 'Custom',
        radioType: undefined,
        permissionError: false,
        dropdownValuePermission: dropdownValue,
        isCustomNotEmpty: includes(isCustomEmpty, false),
        isPermissionLabelVisible: false,
      };
    });
  }

  renderDropdown = () => {
    const { permissionArray } = this.state;
    return (
      <AvForm className="permission-form-custom">
        <Grid>
          <Grid.Row>
            <div className="table-custom-border">
              <Table className="table-custom">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Activity Section</Table.HeaderCell>
                    <Table.HeaderCell>Read</Table.HeaderCell>
                    <Table.HeaderCell>Write</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {permissionTypes.map((permissionType, i) => {
                    return (
                      <Table.Row key={i}>
                        <Table.Cell>{permissionType.title}</Table.Cell>
                        <Table.Cell className="activity-section-cell">
                          <AvCheckboxGroup inline name={permissionType.title}>
                            <AvCheckbox
                              onChange={(e) =>
                                this.selectPermissionOption(
                                  e.target.checked,
                                  e.target.value,
                                  permissionType.id - 1,
                                  'read'
                                )
                              }
                              // label="Read"
                              value={permissionType.title}
                              checked={
                                permissionArray
                                  ? permissionArray[permissionType.id - 1]
                                      .permission.read
                                  : false
                              }
                            />
                          </AvCheckboxGroup>
                        </Table.Cell>
                        <Table.Cell>
                          <AvCheckboxGroup inline name={permissionType.title}>
                            {RESTRICTION_TYPES.includes(
                              permissionType.state
                            ) ? null : (
                              <AvCheckbox
                                onChange={(e) =>
                                  this.selectPermissionOption(
                                    e.target.checked,
                                    e.target.value,
                                    permissionType.id - 1,
                                    'write'
                                  )
                                }
                                value={permissionType.title}
                                checked={
                                  permissionArray
                                    ? permissionArray[permissionType.id - 1]
                                        .permission.write
                                    : false
                                }
                                disabled={
                                  permissionArray
                                    ? !permissionArray[permissionType.id - 1]
                                        .permission.read
                                    : false
                                }
                              />
                            )}
                          </AvCheckboxGroup>
                        </Table.Cell>
                      </Table.Row>
                    );
                  })}
                </Table.Body>
              </Table>
            </div>
          </Grid.Row>
        </Grid>
      </AvForm>
    );
  };

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
      permissionType: undefined,
      dropdownValuePermission: '',
    }));
  }

  render() {
    const {
      isLoading,
      data,
      searchQuery,
      form,
      permissionType,
      permissionError,
      modal,
      dropdownValuePermission,
      isPermissionLabelVisible,
    } = this.state;
    const { currentActivity, user, helpTips, actPut } = this.props;
    return (
      <Segment
        className="act-member-segment"
        disabled={currentActivity.statusId === 3 ? true : false}
      >
        {/* TODO: NEED TO VERIFY ABOVE CONDITION */}
        {user.id == currentActivity.ownerId ? (
          <Grid columns={2} divided stackable>
            <Grid.Row className="act-member-row">
              <Grid.Column
                mobile={16}
                tablet={8}
                computer={16}
                className="add-member-column"
              >
                <Segment
                  disabled={currentActivity.statusId === 3 ? true : false}
                >
                  <div className="custom-segment-no-border">
                    <label className="search-label-custom">
                      Search for existing users in the organisation
                    </label>
                    <div className="search-bar-wrap">
                      {/* 96 */}
                      <CustomTooltip
                        state={get(user, 'options.tips')}
                        contentObject={{
                          data: helpTips,
                          key: '96',
                        }}
                        position="top left"
                        wide="very"
                      >
                        <Search
                          className={
                            currentActivity.statusId === 3
                              ? 'search-bar-custom disabled-ques'
                              : 'search-bar-custom'
                          }
                          loading={isLoading}
                          onResultSelect={(e, { result }) =>
                            this.handleResultSelect(result)
                          }
                          onSearchChange={debounce(
                            this.handleSearchChange,
                            300,
                            {
                              leading: true,
                            }
                          )}
                          results={data}
                          value={searchQuery}
                          resultRenderer={({ name }) => <>{name}</>}
                          placeholder="Search"
                          disabled={
                            currentActivity.statusId === 3 ? true : false
                          }
                        />
                      </CustomTooltip>

                      <CustomTooltip
                        state={get(user, 'options.tips')}
                        contentObject={{
                          data: helpTips,
                          key: '90',
                        }}
                        position="top left"
                        wide="very"
                      >
                        <Button
                          onClick={() => this.toggle()}
                          className="Secondary-Button"
                        >
                          <Icon name="plus" />
                          Add Guest User
                        </Button>
                      </CustomTooltip>
                    </div>

                    <Modal
                      size="tiny"
                      open={modal}
                      closeIcon={
                        <Icon
                          onClick={() => this.toggle()}
                          name="close"
                          className="closeicon"
                        />
                      }
                      className="add-guest-user"
                    >
                      <Modal.Header>Add guest user</Modal.Header>
                      <Modal.Content>
                        <div className="new-member-segment-custom">
                          <AvForm
                            ref={(c) => (this.form = c)}
                            onValidSubmit={(event, values) =>
                              this.handleValidSubmit(event, values)
                            }
                            onInvalidSubmit={() => this.handleInvalidSubmit()}
                            model={form}
                            autoComplete="off"
                          >
                            <Grid>
                              <Grid.Column mobile={16} tablet={8} computer={8}>
                                <AvField
                                  name="firstName"
                                  type="text"
                                  label="First Name"
                                  placeholder="Enter first name"
                                  className="input-fields-radius"
                                  validate={{
                                    required: {
                                      value: true,
                                      errorMessage: 'First name is required',
                                    },
                                  }}
                                  autoComplete="off"
                                />
                              </Grid.Column>
                              <Grid.Column mobile={16} tablet={8} computer={8}>
                                <AvField
                                  name="surname"
                                  type="text"
                                  label="Surname"
                                  placeholder="Enter Surname"
                                  className="input-fields-radius"
                                  validate={{
                                    required: {
                                      value: true,
                                      errorMessage: 'Surname is required',
                                    },
                                  }}
                                  autoComplete="off"
                                />
                              </Grid.Column>
                            </Grid>
                            <AvField
                              name="email"
                              type="email"
                              label="Email"
                              placeholder="Enter Email"
                              className="input-fields-radius"
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: 'Email is required',
                                },
                              }}
                              autoComplete="off"
                            />
                            <AvField
                              name="message"
                              type="textarea"
                              className="input-fields-radius-msg"
                              label={
                                <CustomTooltip
                                  state={get(user, 'options.tips')}
                                  contentObject={{
                                    data: helpTips,
                                    key: '88',
                                  }}
                                  position="top left"
                                  wide="very"
                                >
                                  {/* <div>MESSAGE FOR MEMBER</div> */}
                                  <div>Message for guest user</div>
                                </CustomTooltip>
                              }
                              rows="4"
                              validate={{
                                required: {
                                  value: false,
                                },
                              }}
                            />
                            <CustomTooltip
                              state={get(user, 'options.tips')}
                              contentObject={{
                                data: helpTips,
                                key: '125',
                              }}
                              position="top left"
                              wide="very"
                            >
                              <label>Permission</label>
                            </CustomTooltip>

                            <AvField
                              type="select"
                              name="permission" //this will be deleted in handle valid submit
                              className={`permission-dropdown-custom ${
                                permissionError ? 'invalid-input ' : ' '
                              }`}
                              required
                              onChange={(e) =>
                                this.selectPermissionType(
                                  e.target.value,
                                  e.target.name,
                                  e.target.checked
                                )
                              }
                              value={dropdownValuePermission}
                            >
                              <option value="" defaultValue disabled>
                                Select permission type
                              </option>
                              <option value="collaborator" name="collaborator">
                                Collaborator
                              </option>
                              <option value="documentor" name="documentor">
                                Documentor
                              </option>
                              <option value="reportOnly" name="reportOnly">
                                Report Only
                              </option>
                              <option value="custom" name="custom">
                                Custom
                              </option>
                            </AvField>

                            {permissionType ? this.renderDropdown() : null}

                            {/* }
                              content={this.renderDropdown}
                              on="click"
                              wide="very"
                              position="bottom left"
                            /> */}
                            {permissionError || isPermissionLabelVisible ? (
                              <div className="invalid-permission">
                                Please set permissions for new activity member
                              </div>
                            ) : null}
                            <div className="model-btn-align-right">
                              <Button
                                type="submit"
                                className="Primary-Button"
                                // className="submit-button invite-member-custom-wrap"
                                disabled={actPut.activityMembers ? true : false}
                                loading={actPut.activityMembers ? true : false}
                                // onClick={() => this.toggle()}
                              >
                                <CustomTooltip
                                  state={get(user, 'options.tips')}
                                  contentObject={{
                                    data: helpTips,
                                    key: '89',
                                  }}
                                  position="top left"
                                  wide="very"
                                >
                                  <div>Invite</div>
                                </CustomTooltip>
                              </Button>
                            </div>
                          </AvForm>
                        </div>
                      </Modal.Content>
                    </Modal>
                  </div>
                </Segment>
              </Grid.Column>
            </Grid.Row>

            <Grid.Column
              mobile={16}
              tablet={8}
              computer={16}
              className="table-wrap-setting"
              // className="view-member-column-custom"
            >
              {this.renderCurrentMemberCard()}
              {/* </div> */}
            </Grid.Column>
          </Grid>
        ) : (
          <div>
            <Grid columns={2}>
              <Grid.Row>
                <Grid.Column
                  mobile={16}
                  tablet={8}
                  computer={16}
                  className="view-member-column"
                >
                  {this.renderCurrentMemberCard()}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        )}
      </Segment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.activity,
    user: state.authentication.user,
    helpTips: state.extra.activityHelpTips,
    organization: state.organization,
    extra: state.extra,
    auth: state.authentication,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      addActivityMember,
      deleteActivityMember,
      getOccupationTypes,
      getOccupation,
      // getOrgContractors,
      getOrgMembers,
      reInviteWithdrawnMember,
    },
    dispatch
  );
};

ActivityMemberSteps = connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivityMemberSteps);

export { ActivityMemberSteps };
