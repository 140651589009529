import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { isBrowser, isTablet } from 'react-device-detect';
import {
  Button,
  Checkbox,
  Dropdown,
  Form,
  Icon,
  Label,
  Message,
  Modal,
  Radio,
  Search,
  Segment,
} from 'semantic-ui-react';
import AvField from 'availity-reactstrap-validation/lib/AvField';
import AvForm from 'availity-reactstrap-validation/lib/AvForm';
import React, { Component } from 'react';

import { assign, debounce, isEmpty, map, get } from '../../../../utils/lodash';
import {
  CustomSegment,
  CustomTable,
  EmptyContainer,
} from '../../../../components';

import {
  createNewUsers,
  updateFromSuperAdminUserType,
  resetUserPasswordAdmin,
  updateUserInfo,
  updateGuestUserInfo,
  getAllUsersOrg,
  exportSelectedUser,
  deleteUser,
  getUserActivitiesCount,
  getAllOrganisations,
} from '../../../../store/actions';
import { authMsg, stopPropagation } from '../../../../utils';
import WithdrawUserConfirmationModal from '../../../organization/forms/manageUsers/withdrawUserConfirmationModal';
import PasswordChange from '../../../../components/passwordChange';
import { Formik } from 'formik';
import {
  CustomCheckbox,
  CustomInput,
  CustomPasswordInput,
} from '../../../../components/form';
import {
  addNewUserByAdminInitialValues,
  addNewUserByAdminValidationSchema,
} from '../../../../validations/addNewUserByAdminValidation';

class ParentUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      search: {
        keyword: '',
        organisationId: null,
      },
      disabled: 'active',
    };
  }

  async componentDidMount() {
    const { getAllOrganisations } = this.props;

    await getAllOrganisations();
  }

  async onClickUsersExport() {
    const { search } = this.state;
    await this.props.exportSelectedUser(search);
    const { selectedUserCsvLink } = this.props;

    if (!isEmpty(selectedUserCsvLink)) {
      window.open(selectedUserCsvLink.download_link, '_blank');
    }
  }

  handleSearchChange = async (e, { value }) => {
    await this.setState((preState) => {
      return {
        ...preState,
        currentPage: 1,
        search: { ...preState.search, keyword: value },
      };
    });

    const { getAllUsersOrg } = this.props;
    const { currentPage, search, disabled } = this.state;

    getAllUsersOrg(currentPage, search, disabled);
  };

  setFilterDisabled(disabled) {
    this.setState(
      {
        disabled,
        currentPage: 1,
      },
      async () => {
        const { getAllUsersOrg } = this.props;
        const { currentPage, search, disabled } = this.state;
        await getAllUsersOrg(currentPage, search, disabled);
      }
    );
  }

  async onChangeOrganisation(event, data) {
    await this.setState((preState) => {
      return {
        ...preState,
        currentPage: 1,
        search: { ...preState.search, organisationId: data.value },
      };
    });

    const { getAllUsersOrg } = this.props;
    const { currentPage, search, disabled } = this.state;

    getAllUsersOrg(currentPage, search, disabled);
  }

  pageChange = async (page) => {
    await this.setState({ currentPage: page });

    const { getAllUsersOrg } = this.props;
    const { currentPage, search, disabled } = this.state;

    getAllUsersOrg(currentPage, search, disabled);
  };

  resetPage = () => {
    this.setState({ currentPage: 1 });
  };

  render() {
    const { currentPage, disabled } = this.state;
    return (
      <div>
        <CustomSegment
          title="Manage Users"
          className="manage-users"
          children={
            <Users
              handleSearchChange={(e, value) =>
                this.handleSearchChange(e, value)
              }
              search={this.state.search}
              onChangeOrganisation={(event, data) =>
                this.onChangeOrganisation(event, data)
              }
              setFilterDisabled={(disabled) => this.setFilterDisabled(disabled)}
              currentPage={currentPage}
              disabled={disabled}
              pageChange={this.pageChange}
              resetPage={this.resetPage}
              {...this.props}
            />
          }
          rightHeader={
            <div className="export-dropdown-btn">
              <Button size="small" className="Primary-Button">
                <Dropdown text={'Export'} direction="left" icon="angle down">
                  <Dropdown.Menu>
                    <Dropdown.Item
                      text="Download Takso Users"
                      onClick={() => this.onClickUsersExport()}
                    />
                  </Dropdown.Menu>
                </Dropdown>
              </Button>
            </div>
          }
        />
        {/* TODO:  this feature will be enabled in future card */}
        {/* <div className="mt-5">
          <CustomSegment
            title="Reassign Activities"
            className="reassign-activities"
            children={<ReassignActivity />}
          />
        </div> */}
      </div>
    );
  }
}

const setDisable_option = [
  {
    key: 'all',
    text: <b>All</b>,
    value: 'all',
  },
  {
    key: 'active',
    text: <b>Active</b>,
    value: 'active',
  },
  {
    key: 'inactive',
    text: <b>Inactive</b>,
    value: 'inactive',
  },
];
export class Users extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  renderOrganizations() {
    const { organisationsAll } = this.props.admin;
    const optionList = map(organisationsAll, (item, i) => ({
      key: item.id,
      value: item.id,
      text: item.name,
    }));
    optionList.unshift({ key: null, value: null, text: '-' });

    return optionList;
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }
  render() {
    const { modal } = this.state;
    const {
      onChangeOrganisation,
      handleSearchChange,
      setFilterDisabled,
      disabled,
      search,
      resetPage,
    } = this.props;
    const orgOptions = this.renderOrganizations();

    return (
      <div>
        <div className="outcome-admin-users">
          <div className="user-search-admin">
            <div className="org-search">
              <label>Select an organisation</label>
              <Dropdown
                className="dropdown-org-user"
                placeholder="Select organization"
                fluid
                search
                selection
                onChange={(event, data) => onChangeOrganisation(event, data)}
                options={orgOptions}
              />
            </div>
            <div className="search-bar-user">
              <label>Search by user</label>
              <Search
                onSearchChange={debounce(handleSearchChange, 1000, {
                  leading: true,
                })}
                value={search.value}
                placeholder="Search by User"
              />
            </div>
            <div className="org-search">
              <label>Status</label>
              <Dropdown
                className="dropdown-org-user"
                placeholder="Select Status"
                fluid
                search
                selection
                options={setDisable_option}
                value={disabled}
                onChange={(event, { value }) => {
                  const selectedOption = setDisable_option.find(
                    (option) => option.value === value
                  );
                  if (selectedOption) {
                    setFilterDisabled(selectedOption.value);
                  }
                }}
              />
            </div>
          </div>
          <div className="button-outcome">
            <Modal
              open={modal}
              onOpen={() => this.toggle()}
              closeIcon={
                <Icon
                  onClick={() => this.toggle()}
                  name="close"
                  className="closeicon"
                />
              }
              size="small"
              trigger={
                <Button className="Primary-Button">
                  <div className="add-icon-activity-add">
                    <Icon name="plus circle" />
                    <div className="add-text">Add New User</div>
                  </div>
                </Button>
              }
              className="add-new-user"
            >
              <Modal.Header>Add New User</Modal.Header>
              <Modal.Content>
                <AddNewUser toggle={() => this.toggle()} {...this.props} />
              </Modal.Content>
            </Modal>
          </div>
        </div>
        <UsersList resetPage={resetPage} {...this.props} />
      </div>
    );
  }
}

class AddNewUser extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isShownPass: false,
      isTaksoAdminEmail: false,
    };
  }

  handleValidSubmit(values) {
    try {
      const { isTaksoAdminEmail } = this.state;
      const { createNewUsers, toggle, resetPage } = this.props;
      if (Object.keys(values).includes('confirmPassword')) {
        delete values.confirmPassword;
      }

      values.isTaksoAdminEmail = isTaksoAdminEmail;
      createNewUsers(values);

      if (resetPage) resetPage();
      toggle();
    } catch (error) {
      error.log('AddNewUser ~ handleValidSubmit ~ error', error);
    }
  }

  getOrganizations() {
    const { organisationsAll } = this.props.admin;
    return map(organisationsAll, (item, i) => ({
      key: item.id,
      value: item.id,
      text: item.name,
    }));
  }

  setIsTaksoAdminEmail() {
    this.setState((prevState) => ({
      isTaksoAdminEmail: !prevState.isTaksoAdminEmail,
    }));
  }

  render() {
    const { toggle } = this.props;
    const { isShownPass, isTaksoAdminEmail } = this.state;

    const { userPostLoading } = this.props.admin;

    return (
      <Formik
        initialValues={addNewUserByAdminInitialValues()}
        onSubmit={(values) => {
          this.handleValidSubmit(values);
        }}
        validationSchema={addNewUserByAdminValidationSchema(isTaksoAdminEmail)}
      >
        {({ handleSubmit, isSubmitting, setFieldValue, errors }) => (
          <Form onSubmit={handleSubmit}>
            <CustomInput
              name="firstName"
              id="firstName"
              labelText="First Name"
              placeholder="Enter First Name"
              // disabled={disabled}
            />

            <CustomInput
              name="surname"
              id="surname"
              labelText="Surname"
              placeholder="Enter Surname"
              // disabled={disabled}
            />

            <CustomCheckbox
              className="check-box"
              id="isTaksoAdminEmail"
              name="isTaksoAdminEmail"
              label="Takso support email  (system won't send emails to this email)"
              checked={isTaksoAdminEmail}
              onChange={() => this.setIsTaksoAdminEmail()}
            />

            <CustomInput
              name="email"
              id="email"
              labelText="Email"
              placeholder="Enter email"
              // disabled={disabled}
            />

            <div>
              {' '}
              <label>Organisation</label>
              <Dropdown
                onChange={
                  (event, data) => setFieldValue('organisationId', data.value)
                  // this.onChangeOrganization(event, data)
                }
                className="org-inputs"
                placeholder="Select Organisation"
                fluid
                search
                selection
                options={this.getOrganizations()}
              />
              <p className="formik-error-msg">
                {' '}
                {errors.organisationId && errors.organisationId}
              </p>
            </div>

            {isTaksoAdminEmail && (
              <>
                {' '}
                <CustomPasswordInput
                  id="password"
                  name="password"
                  labelText="New Password"
                  placeholder="New Password"
                />
                <CustomInput
                  id="confirmPassword"
                  name="confirmPassword"
                  placeholder="Confirm Password"
                  labelText="Confirm Password"
                  type={isShownPass ? 'text' : 'password'}
                  icon={
                    <Icon
                      name={isShownPass ? 'eye' : 'eye slash'}
                      onClick={() => {
                        this.setState({ isShownPass: !isShownPass });
                      }}
                      link
                    />
                  }
                />
              </>
            )}

            <CustomInput
              name="positionTitle"
              id="positionTitle"
              labelText="Position / Designation (Optional)"
              placeholder="Enter Position / Designation"
              // disabled={disabled}
            />
            <CustomInput
              name="phone"
              id="phone"
              labelText="Phone"
              placeholder="Enter Phone"
              // disabled={disabled}
            />
            <div className="model-buttons">
              <Button
                className="modal-cancel"
                type="button"
                onClick={() => toggle()}
              >
                Cancel
              </Button>
              <Button
                content="Create"
                type="submit"
                className="modal-proceed"
                loading={userPostLoading}
                disabled={userPostLoading}
              />
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

class UsersList extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
      withdrawUserConfirmationModal: false,
      selectedUser: {},
    };
  }

  columns = [
    {
      title: (
        <div className="user-heading-container">
          <strong>Name</strong>
        </div>
      ),
      render: (data) => {
        return (
          <div className="user-name-badge">
            <div
              className={
                data.disabled ? 'venue-name-disabled' : 'org-users-container'
              }
            >
              <span className="org-users-cdn-container">
                <label className="org-users-mainText">{data.name}</label>
              </span>
            </div>
            <label className="org-users-text">
              {data.disabled ? (
                <Label className="user-badge" color="red" size="tiny">
                  Disabled
                </Label>
              ) : null}
              <Label className="user-badge" color="orange" size="tiny">
                {this.renderUserType(data.role)}
              </Label>
              {String(data.email).includes('cdn-admin@') ? (
                <Label color="grey" size="tiny" basic>
                  CDN Admin
                </Label>
              ) : null}
            </label>
          </div>
        );
      },
    },
    {
      title: (
        <div className="user-heading-container">
          <strong>Email</strong>
        </div>
      ),
      render: (data) => {
        return (
          <div
            className={
              data.disabled ? 'venue-name-disabled' : 'org-users-container'
            }
          >
            {data.email}
          </div>
        );
      },
      textAlign: isBrowser || isTablet ? 'left' : null,
    },
    {
      title: (
        <div className="user-heading-container">
          <strong>Organisation</strong>
        </div>
      ),
      render: (data) => {
        return (
          <div
            className={
              data.disabled ? 'venue-name-disabled' : 'org-users-container'
            }
          >
            {get(data, 'organisation.name', '-')}
          </div>
        );
      },
      textAlign: isBrowser || isTablet ? 'left' : null,
    },
    {
      render: (data) => {
        const { userActivities } = this.props;
        return (
          <div className="remove-user">
            <UserDelete
              user={data}
              {...this.props}
              withdrawUserConfirmationModalHandler={(status, user) =>
                this.withdrawUserConfirmationModalHandler(status, user)
              }
            />
            <WithdrawUserConfirmationModal
              isAdmin
              user={this.state.selectedUser}
              userActivities={userActivities}
              // {...this.props}
              resetPage={() => this.props.resetPage()}
              modal={this.state.withdrawUserConfirmationModal}
              handleModal={(status) =>
                this.withdrawUserConfirmationModalHandler(status)
              }
            />
          </div>
        );
      },
      textAlign: isBrowser || isTablet ? 'left' : null,
    },
    {
      render: (data) => {
        return (
          <div className="owned">
            <Dropdown
              icon="ellipsis vertical"
              direction="left"
              inline
              selectOnBlur={false}
            >
              <Dropdown.Menu>
                <EditUser data={data} {...this.props} />
                <UserPassChange user={data} {...this.props} />
                {data.organisationId && (
                  <UserTypeChange user={data} {...this.props} {...this.props} />
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        );
      },
      textAlign: isBrowser || isTablet ? 'center' : null,
    },
  ];

  withdrawUserConfirmationModalHandler(status, selectedUser) {
    this.setState({ withdrawUserConfirmationModal: status, selectedUser });
  }

  renderUserType(role) {
    switch (role) {
      case 'organisationAdmin':
        return 'Organisation Admin';
      case 'user':
        return 'User';
      case 'guest':
        return 'Guest';
      case 'admin':
        return 'Super Admin';
      default:
        break;
    }
  }

  async componentDidMount() {
    try {
      const { getAllUsersOrg, currentPage } = this.props;
      await getAllUsersOrg(currentPage);
    } catch (error) {
      error.log('UsersList ~ componentDidMount ~ error', error);
    }
  }

  render() {
    const {
      admin: { allUserList, orgAllLoading },
      currentPage,
    } = this.props;

    return (
      <Segment
        className="content-segment-users"
        loading={orgAllLoading}
        disabled={orgAllLoading}
      >
        {allUserList.docs ? (
          <CustomTable
            header
            columns={this.columns}
            data={allUserList.docs}
            pagination
            handlePaginationChange={(page) => this.props.pageChange(page)}
            customClass="super-admin-table tbody-hover"
            page={currentPage}
            noOfPages={allUserList.pages}
            keyExtractor={(item, index) => item.id}
          />
        ) : (
          <EmptyContainer msg="No Users..." />
        )}
      </Segment>
    );
  }
}

class UserPassChange extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  render() {
    const { modal } = this.state;
    const { user } = this.props;
    return (
      <div className="add-user">
        <Modal
          trigger={
            <Dropdown.Item>
              <label className="edit-org">
                Reset Password
                <Icon name="write" />
              </label>
            </Dropdown.Item>
          }
          size="tiny"
          open={modal}
          onOpen={() => this.toggle()}
          onClick={(e) => stopPropagation(e)}
          onFocus={(e) => stopPropagation(e)}
          closeIcon={
            <Icon
              onClick={() => this.toggle()}
              name="close"
              className="closeicon"
            />
          }
        >
          <Modal.Header>Reset Password - {user.name}</Modal.Header>
          <Modal.Content>
            <PassChangeForm toggle={() => this.toggle()} {...this.props} />
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}
class PassChangeForm extends React.Component {
  handleValidSubmit(values) {
    try {
      const { user, toggle, resetUserPasswordAdmin } = this.props;

      const form = assign(user, values);

      resetUserPasswordAdmin(form);

      this.form && this.form.reset();
      toggle();
    } catch (error) {
      error.log('PassChangeForm ~ handleValidSubmit ~ error', error);
    }
  }

  render() {
    const { toggle } = this.props;
    return (
      <PasswordChange
        toggle={() => toggle()}
        handleValidSubmit={(values) => {
          this.handleValidSubmit(values);
        }}
      />
    );
  }
}

class EditUser extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      form: {
        firstName: '',
        surName: '',
        positionTitle: '',
        phone: '',
        email: '',
        isTaksoAdminEmail: false,
        organisationId: '',
      },
    };
  }

  editUser(form) {
    form.phone = form.phone ? form.phone.replace(/\D/g, '') : '';
    this.setState({
      form,
    });
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }
  render() {
    const { modal, form } = this.state;

    const { data } = this.props;

    return (
      <div>
        <div className="button-outcome">
          <Modal
            open={modal}
            onOpen={() => this.toggle()}
            onClick={(e) => stopPropagation(e)}
            onFocus={(e) => stopPropagation(e)}
            closeIcon={
              <Icon
                onClick={() => this.toggle()}
                name="close"
                className="closeicon"
              />
            }
            size="small"
            trigger={
              <Dropdown.Item>
                <label className="edit-org" onClick={() => this.editUser(data)}>
                  Edit User
                  <Icon name="write" />
                </label>
              </Dropdown.Item>
            }
          >
            <Modal.Header>Edit User</Modal.Header>
            <Modal.Content>
              <EditUserModalForm
                form={form}
                toggle={() => this.toggle()}
                {...this.props}
              />
            </Modal.Content>
          </Modal>
        </div>
      </div>
    );
  }
}

class EditUserModalForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      organisationId: '',
      disabled: !get(this.props, 'form.organisation.isActive', true),
      isTaksoAdminEmail: get(this.props, 'form.isTaksoAdminEmail', false),
    };
  }

  async handleValidSubmit(event, values) {
    try {
      const {
        form,
        toggle,
        updateUserInfo,
        updateGuestUserInfo,
        resetPage,
        currentPage,
      } = this.props;
      const { disabled, isTaksoAdminEmail } = this.state;
      values.disabled = disabled;
      values.isTaksoAdminEmail = isTaksoAdminEmail;

      form.organisation !== null
        ? await updateUserInfo(
            form.organisation.id,
            form.id,
            values,
            currentPage
          )
        : await updateGuestUserInfo(form.id, values, currentPage);

      if (resetPage) resetPage();
      toggle();
    } catch (error) {
      error.log('EditUserModalForm ~ handleValidSubmit ~ error', error);
    }
  }

  toggleCheckbox() {
    this.setState((prevState) => {
      return { disabled: !prevState.disabled };
    });
  }

  componentDidMount() {
    const { form } = this.props;
    this.setState({ disabled: Boolean(form.disabled) });
  }

  setIsTaksoAdminEmail() {
    this.setState((prevState) => ({
      isTaksoAdminEmail: !prevState.isTaksoAdminEmail,
    }));
  }
  render() {
    const { toggle, form, userUpdateLoading } = this.props;

    const { disabled, isTaksoAdminEmail } = this.state;

    const isGuestUser = !Boolean(get(this.props, 'form.organisationId', null));

    return (
      <AvForm
        ref={(c) => (this.form = c)}
        model={form}
        onValidSubmit={(event, values) => this.handleValidSubmit(event, values)}
        className="venue-form"
      >
        <AvField
          label="First Name"
          className="org-inputs"
          name="firstName"
          type="text"
          validate={{
            required: {
              value: true,
              errorMessage: 'First Name is required',
            },
          }}
        />
        <div className="gap-between-edit">
          <AvField
            label="Surname"
            className="org-inputs"
            name="surname"
            type="text"
            validate={{
              required: {
                value: true,
                errorMessage: 'SURNAME Name is required',
              },
            }}
          />
        </div>

        {!isGuestUser && (
          <div className="gap-between-edit">
            <Checkbox
              className="check-box"
              id="isTaksoAdminEmail"
              name="isTaksoAdminEmail"
              label="Takso support email  (system won't send emails to this email)"
              checked={isTaksoAdminEmail}
              onChange={() => this.setIsTaksoAdminEmail()}
            />
          </div>
        )}

        <div className="gap-between-edit">
          <AvField
            label="Email"
            className="org-inputs"
            name="email"
            type="email"
            validate={{
              required: {
                value: true,
                errorMessage: 'EMAIL is required',
              },
            }}
          />
        </div>
        <div className="gap-between-edit">
          <AvField
            label="Position / Designation (Optional)"
            className="org-inputs"
            name="positionTitle"
            autoComplete="off"
            type="text"
          />
        </div>
        <AvField
          label="Phone (Optional)"
          className="org-inputs"
          name="phone"
          type="number"
          validate={{
            maxLength: {
              value: 15,
              errorMessage: 'Must be a minimum of 15 digits',
            },
          }}
        />

        <div className="edit-outcome-status-bar">
          <label>Disable</label>
          <div className="two-step-radio-container-admin status-left">
            <Radio
              name="isEnable"
              toggle
              className="modal-toggle"
              checked={disabled}
              onChange={() => this.toggleCheckbox()}
              disabled={!get(this.props, 'form.organisation.isActive', true)}
            />
          </div>
        </div>
        {!get(this.props, 'form.organisation.isActive', true) && (
          <div className="msg-report">
            <Message
              color="blue"
              icon="exclamation circle"
              content="The account associated with this user is currently disabled"
            />
          </div>
        )}

        <div className="model-buttons">
          <Button
            className="modal-cancel"
            type="button"
            onClick={() => toggle()}
          >
            Cancel
          </Button>
          <Button
            content="Save"
            type="submit"
            className="modal-proceed"
            loading={userUpdateLoading}
            disabled={userUpdateLoading}
          />
        </div>
      </AvForm>
    );
  }
}
class UserTypeChange extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  render() {
    const { modal } = this.state;
    const { user } = this.props;

    return (
      <div className="add-user">
        <Modal
          trigger={
            <Dropdown.Item>
              <label className="edit-user">
                Change User Type
                <Icon className="ml-2" name="write" />
              </label>
            </Dropdown.Item>
          }
          // trigger={<label className="user-type-change"></label>}
          size="small"
          open={modal}
          onOpen={() => this.toggle()}
          onClick={(e) => stopPropagation(e)}
          onFocus={(e) => stopPropagation(e)}
          closeIcon={
            <Icon
              onClick={() => this.toggle()}
              name="close"
              className="closeicon"
            />
          }
        >
          <Modal.Header>Change User Type - {user.name}</Modal.Header>
          <Modal.Content>
            <UserTypeForm toggle={() => this.toggle()} {...this.props} />
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}
class UserTypeForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleValidSubmit(event, values) {
    try {
      const { updateFromSuperAdminUserType, toggle, user, resetPage } =
        this.props;
      let form = {
        role: {},
      };
      if (values.role === 'organisationAdmin') {
        form.role.organisationAdmin = true;
      } else {
        form.role.organisationAdmin = false;
      }

      updateFromSuperAdminUserType(user.organisationId, user.id, form);
      this.form && this.form.reset();
      if (resetPage) resetPage();
      toggle();
    } catch (error) {
      error.log('UserTypeForm ~ handleValidSubmit ~ error', error);
    }
  }

  render() {
    const { toggle, user } = this.props;
    const defaultValue = user;
    return (
      <AvForm
        model={defaultValue}
        ref={(c) => (this.form = c)}
        onValidSubmit={(event, values) => this.handleValidSubmit(event, values)}
      >
        <AvField
          label="User type"
          className="org-inputs"
          name="role"
          type="select"
          validate={{
            required: {
              value: true,
              errorMessage: 'User type is required',
            },
          }}
        >
          <option value="" disabled defaultValue>
            Select an User type
          </option>
          <option value="organisationAdmin">Organisation Admin</option>
          <option value="user">User</option>
        </AvField>
        <div className="model-buttons">
          <Button
            className="Secondary-Button"
            type="button"
            onClick={() => toggle()}
          >
            Cancel
          </Button>
          <Button content="Update" type="submit" className="Primary-Button" />
        </div>
      </AvForm>
    );
  }
}
class UserDelete extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  async toggle() {
    const { modal } = this.state;
    const { user, getUserActivitiesCount } = this.props;
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
    !modal && (await getUserActivitiesCount(user.id));
  }

  handleDeleteOrgUser() {
    const {
      user,
      resetPage,
      withdrawUserConfirmationModalHandler,
      userActivities,
      deleteUser,
    } = this.props;
    if (
      (userActivities.inDevelopmentInvited > 0 ||
        userActivities.inActiveInvited > 0) &&
      userActivities.inDevelopmentOwned === 0 &&
      userActivities.inActiveOwned === 0
    ) {
      this.toggle();
      withdrawUserConfirmationModalHandler(true, user);
    } else {
      if (
        userActivities.inDevelopmentOwned > 0 ||
        userActivities.inActiveOwned > 0
      ) {
        authMsg(
          'error',
          `User has ${
            userActivities.inDevelopmentOwned + userActivities.inActiveOwned
          } active / development activities.
          Activities for this user must first be reassigned.`
        );
      } else {
        deleteUser(user.id);
      }
      if (resetPage) resetPage();
      this.toggle();
    }
  }

  render() {
    const { modal } = this.state;

    const { userRemoveLoading } = this.props.admin;

    return (
      <Modal
        open={modal}
        onOpen={() => this.toggle()}
        closeIcon={
          <Icon
            onClick={() => this.toggle()}
            name="close"
            className="closeicon"
          />
        }
        size="mini"
        trigger={<label className="user-remove first-down">Remove</label>}
        className="delete-user"
      >
        <Modal.Header>Delete User </Modal.Header>
        <Modal.Content>
          <p>Are you sure you want to delete this user?</p>
        </Modal.Content>
        <Modal.Actions>
          <Button className="Secondary-Button" onClick={() => this.toggle()}>
            No
          </Button>
          <Button
            onClick={() => this.handleDeleteOrgUser()}
            loading={userRemoveLoading}
            disabled={userRemoveLoading}
            className="Primary-Button"
          >
            Yes
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    auth: state.authentication,
    organization: state.organization,
    admin: state.admin,
    userUpdateLoading: state.organization.userUpdateLoading,
    selectedUserCsvLink: state.admin.selectedUserCsvLink,
    userActivities: state.organization.userActivities,
    ...state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getAllUsersOrg,
      createNewUsers,
      updateFromSuperAdminUserType,
      resetUserPasswordAdmin,
      updateUserInfo,
      updateGuestUserInfo,
      deleteUser,
      exportSelectedUser,
      getUserActivitiesCount,
      getAllOrganisations,
    },
    dispatch
  );
};

ParentUsers = connect(mapStateToProps, mapDispatchToProps)(ParentUsers);
export { ParentUsers };
