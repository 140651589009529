import React from 'react';
import { useDispatch } from 'react-redux';
import { Button, Icon, Modal } from 'semantic-ui-react';
import { deleteOrganizationUser, deleteUser } from '../../../../store/actions';
import { trimText } from '../../../../utils/';

const WithdrawUserConfirmationModal = ({
  user,
  userActivities,
  modal,
  handleModal,
  isAdmin = null,
  ...props
}) => {
  const dispatch = useDispatch();

  const handleDeleteOrgUser = () => {
    const { resetPage } = props;
    const isWithdrawnActivities = true;

    if (isAdmin) {
      dispatch(deleteUser(user.id, isWithdrawnActivities));
    } else {
      dispatch(deleteOrganizationUser(user.id, isWithdrawnActivities));
    }
    if (resetPage) resetPage();
  };

  const invitedDevelopmentActivities = userActivities.inDevelopmentInvited
    ? userActivities.inDevelopmentInvited
    : 0;

  const invitedActiveActivities = userActivities.inActiveInvited
    ? userActivities.inActiveInvited
    : 0;

  return (
    <>
      <Modal
        open={modal}
        closeIcon={
          <Icon
            onClick={() => handleModal(false)}
            name="close"
            className="closeicon"
          />
        }
        size="tiny"
      >
        <Modal.Header>
          Remove User Invited Activities - {user && trimText(user.name, 15)}
        </Modal.Header>
        <Modal.Content>
          <>
            {invitedDevelopmentActivities > 0 &&
              invitedActiveActivities === 0 && (
                <p>
                  This user is invited to {invitedDevelopmentActivities}{' '}
                  {invitedDevelopmentActivities === 1
                    ? 'in development activity'
                    : 'in development activities'}
                  . On confirming removal, the user will be withdrawn from all
                  invited activities.
                </p>
              )}
            {invitedDevelopmentActivities === 0 &&
              invitedActiveActivities > 0 && (
                <p>
                  This user is invited to {invitedActiveActivities}{' '}
                  {invitedActiveActivities === 1
                    ? 'active activity'
                    : 'active activities'}
                  . On confirming removal, the user will be withdrawn from all
                  invited activities.
                </p>
              )}
            {invitedDevelopmentActivities > 0 &&
              invitedActiveActivities > 0 && (
                <p>
                  This user is invited to {invitedActiveActivities}{' '}
                  {invitedActiveActivities === 1
                    ? 'active activity'
                    : 'active activities'}{' '}
                  and {invitedDevelopmentActivities}{' '}
                  {invitedDevelopmentActivities === 1
                    ? 'in development activity'
                    : 'in development activities'}
                  . On confirming removal, the user will be withdrawn from all
                  invited activities.
                </p>
              )}
          </>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={() => {
              handleModal(false);
            }}
            className="modal-cancel"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleModal(false);
              handleDeleteOrgUser(true);
            }}
            className="modal-proceed"
            content="Confirm"
          />
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default WithdrawUserConfirmationModal;
